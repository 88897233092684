const calculatorForm = document.getElementById('calculator');
const sizeUnit = document.getElementById('size-unit');
const resultsDiv = document.getElementById('results');

// Conversion factors for imperial and metric systems
const conversionFactors = {
  imperial: {
    water: 0.3578758511,
    flour: 0.6222133225,
    salt: 0.0186663997,
    yeast: 0.0012444266,
    size: {
      small: {
        weight: 6.35,
        diameter: 9
      },
      medium: {
        weight: 8.8,
        diameter: 11
      },
      large: {
        weight: 11.6,
        diameter: 13
      }
    }
  },
  metric: {
    water: 0.3676470588,
    flour: 0.612745098,
    salt: 0.01838235294,
    yeast: 0.001225490196,
    size: {
      small: {
        weight: 180,
        diameter: 22
      },
      medium: {
        weight: 250,
        diameter: 28
      },
      large: {
        weight: 330,
        diameter: 33
      }
    }
  }
};

// Update the size unit based on the selected system
function updateSizeUnit(system) {
  const size = document.getElementById('size').value;
  const unit = system === 'imperial' ? 'inches' : 'cm';
  const sizeData = conversionFactors[system].size[size];
  sizeUnit.textContent = ` (${sizeData.weight}g, ${sizeData.diameter}${unit})`;
}

// Calculate the ingredient amounts and display the results
function calculate(e) {e.preventDefault(); // Prevent form submission
  const system = document.getElementById('system').value;
  const size = document.getElementById('size').value;
  const pizzas = parseInt(document.getElementById('pizzas').value);
  const sizeData = conversionFactors[system].size[size];
  const doughWeight = sizeData.weight * pizzas;
  const water = doughWeight * conversionFactors[system].water;
  const flour = doughWeight * conversionFactors[system].flour;
  const salt = doughWeight * conversionFactors[system].salt;
  const yeast = doughWeight * conversionFactors[system].yeast;

  resultsDiv.innerHTML = `
    <p>For ${pizzas} pizzas, each ${sizeData.diameter}${sizeUnit.textContent}:</p>
    <ul>
      <li>${water.toFixed(1)} ${system === 'imperial' ? 'fl oz' : 'ml'} of water</li>
      <li>${flour.toFixed(1)} ${system === 'imperial' ? 'oz' : 'g'} of flour</li>
      <li>${salt.toFixed(1)} ${system === 'imperial' ? 'oz' : 'g'} of salt</li>
      <li>${yeast.toFixed(1)} ${system === 'imperial' ? 'oz' : 'g'} of fresh yeast</li>
    </ul>
  `;
}

// Event listeners
document.addEventListener('DOMContentLoaded', () => {
  updateSizeUnit('imperial'); // Default to imperial
});
document.getElementById('system').addEventListener('change', e => {
  updateSizeUnit(e.target.value);
});
calculatorForm.addEventListener('submit', calculate);
